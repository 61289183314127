import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'presencePart',
    standalone: true
})
export class PresencePartPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let result = '';
    switch (value) {
      case '1':
        result = 'Vorm. 1';
        break;

      case '2':
        result = 'Nachm. 1';
        break;

      case '3':
        result = 'Abend';
        break;

      case '4':
        result = 'Vorm. 2';
        break;

      case '5':
        result = 'Nachm. 2';
        break;

      default:
        result = '';
        break;
    }

    return result;
  }
}
